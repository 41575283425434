import type { TagParseRule } from "@remirror/pm/model";
import {
	ApplySchemaAttributes,
	CommandFunction,
	ExtensionTag,
	MarkExtension,
	MarkExtensionSpec,
	MarkSpecOverride,
	PrimitiveSelection,
	command,
	extension,
	getTextSelection,
} from "remirror";

@extension({ defaultOptions: {} })
export class InlineAiExtension extends MarkExtension {
	get name() {
		return "inline-ai" as const;
	}

	createTags() {
		return [ExtensionTag.FormattingMark, ExtensionTag.FontStyle];
	}

	createMarkSpec(
		extra: ApplySchemaAttributes,
		override: MarkSpecOverride,
	): MarkExtensionSpec {
		return {
			...override,
			attrs: extra.defaults(),
			parseDOM: [
				{
					tag: "inline-ai",
					getAttrs: extra.parse,
				} satisfies TagParseRule,
				...(override.parseDOM ?? []),
			],
			toDOM: (node) => {
				return [
					"span",
					{ style: "background-color: rgba(211, 63,139, 0.3)" },
					0,
				];
			},
		};
	}

	@command()
	toggleInlineAiMark(selection?: PrimitiveSelection): CommandFunction {
		return ({ tr, dispatch }) => {
			const { from, to } = getTextSelection(
				selection ?? tr.selection,
				tr.doc,
			);

			if (!dispatch) return true;

			if (tr.doc.rangeHasMark(from, to, this.type)) {
				tr.removeMark(from, to, this.type);
			} else {
				tr.addMark(from, to, this.type.create());
			}

			tr.setMeta("addToHistory", false);

			dispatch(tr);
			return true;
		};
	}

	@command()
	setInlineAiMark(selection?: PrimitiveSelection): CommandFunction {
		return ({ tr, dispatch }) => {
			const { from, to } = getTextSelection(
				selection ?? tr.selection,
				tr.doc,
			);

			tr.setMeta("addToHistory", false);

			dispatch?.(tr.addMark(from, to, this.type.create()));

			return true;
		};
	}

	@command()
	removeInlineAiMark(selection?: PrimitiveSelection): CommandFunction {
		return ({ tr, dispatch }) => {
			const { from, to } = getTextSelection(
				selection ?? tr.selection,
				tr.doc,
			);

			if (!tr.doc.rangeHasMark(from, to, this.type)) {
				return false;
			}

			tr.setMeta("addToHistory", false);

			dispatch?.(tr.removeMark(from, to, this.type));

			return true;
		};
	}

	@command()
	doesDocumentHaveInlineAiMark(): CommandFunction {
		return ({ state }) => {
			return state.doc.rangeHasMark(0, state.doc.nodeSize - 2, this.type);
		};
	}

	@command()
	removeAllInlineAiMarks(): CommandFunction {
		return ({ state, dispatch }) => {
			const tr = state.tr.removeMark(
				0,
				state.doc.nodeSize - 2,
				this.type,
			);

			tr.setMeta("addToHistory", false);

			dispatch?.(tr);
			return true;
		};
	}
}
