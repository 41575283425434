const editorItalic = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.125 5.625V4.375H8V5.625H11.2125L8.48125 14.375H4.875V15.625H13V14.375H9.7875L12.5187 5.625H16.125Z"
				fill={color}
			/>
		</svg>
	);
};

export default editorItalic;
