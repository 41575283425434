const tIcon = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.077 15V6.103H5.64V4.92H13.76V6.103H10.323V15H9.077Z"
				fill={color}
			/>
		</svg>
	);
};

export default tIcon;
