const h4Icon = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.48 15V4.92H1.726V9.365H7.032V4.92H8.278V15H7.032V10.548H1.726V15H0.48ZM17.3985 15V13.145H12.7645V11.962L16.0895 4.92H17.5035L14.1785 11.962H17.3985V9.127H18.6585V11.962H19.7085V13.145H18.6585V15H17.3985Z"
				fill={color}
			/>
		</svg>
	);
};

export default h4Icon;
