import { Collection } from "../core/types/collections";
// import { Id } from "../core/types/baseTypes"
import { GigaUserApi } from "@giga-user-fern/api";

import { GuidePreview } from "../core/types/guide";
import { FileFormat } from "@giga-user-fern/api/types/api/resources/guides/resources/guideTypes";

export type Item =
	| {
			type: "Collection";
			entry: Collection;
	  }
	| {
			type: "Guide";
			entry: GuidePreview;
	  };
export type GuideFile =
	| (SlideFile & { type: "Slide" })
	| (VideoFile & { type: "Video" });

export type SlideFile = {
	id: string;
	format: "pdf" | "png" | "jpg" | "jpeg";
	fileName: string;
	file: File | null;
	size: string;
	totalSlides: number;
};

export type VideoFile = {
	id: string;
	format: "mp4" | "mov" | "wav" | "webm";
	fileName: string;
	file: File | null;
	size: string;
	duration: number;
};

export type FileType = "Folder" | "Reading" | "Writing";

const rootCollection: Collection = {
	id: GigaUserApi.baseTypes.Id("Collection_root"),
	name: "Projects",
	sequenceNumber: 1,
	parentId: undefined,
};

const unravelItem: (item: Item) => {
	name: string;
	description?: string;
	parentId?: GigaUserApi.Id;
} = (item) => {
	var name = "";
	var description: string | undefined;
	var parentId: GigaUserApi.Id | undefined;

	if (item.type === "Collection") {
		name = item.entry.name;
		description = item.entry.description;
		parentId = item.entry.parentId;
	} else if (item.type === "Guide") {
		name = item.entry.header.name;
		description = item.entry.header.description;
		parentId = item.entry.parentId;
	}

	return {
		name,
		description,
		parentId,
	};
};

export { rootCollection, unravelItem };
