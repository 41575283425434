import {
	useActive,
	useChainedCommands,
	useCommands,
	useRemirrorContext,
} from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import "./BlockTypeDropdown.css";
import Icon from "../../../../../../ui/Icon/Icon";
import ToolbarButton from "../ToolbarButton/ToolbarButton";
import WhiteHoverTip from "../../../../../../ui/WhiteHoverTip/WhiteHoverTip";
import tIcon from "../../../../../../assets/svgs/tIcon";
import h1Icon from "../../../../../../assets/svgs/h1Icon";
import h2Icon from "../../../../../../assets/svgs/h2Icon";
import h3Icon from "../../../../../../assets/svgs/h3Icon";
import h4Icon from "../../../../../../assets/svgs/h4Icon";
type BlockTypeDropdownProps = {
	disabled?: boolean;
};

const BlockTypeDropdown: React.FC<BlockTypeDropdownProps> = (props) => {
	const mediumGrey = "#4b4b4b";
	const active = useActive();

	const commands = useChainedCommands() as any;
	const handler = (level: string | number) => {
		if (level !== "default") {
			commands.toggleHeading({ level }).focus().run();
		} else {
			let activeHeading: string | number = "default";
			[1, 2, 3, 4].forEach((level) => {
				if (active.heading({ level: level })) {
					activeHeading = level;
				}
			});
			if (activeHeading === "default") return;
			commands.toggleHeading({ level: activeHeading }).focus().run();
		}
	};

	const items = [
		{
			active: () => active.heading({ level: "default" }),
			handler: () => handler("default"),
			source: tIcon("white"),
			newHoverTip: <WhiteHoverTip text="Regular Text" />,
		},
		{
			active: () => active.heading({ level: 1 }),
			handler: () => handler(1),
			source: h1Icon("white"),
			newHoverTip: <WhiteHoverTip text="Title Heading" />,
		},
		{
			active: () => active.heading({ level: 2 }),
			handler: () => handler(2),
			source: h2Icon("white"),
			newHoverTip: <WhiteHoverTip text="Large Heading" />,
		},
		{
			active: () => active.heading({ level: 3 }),
			handler: () => handler(3),
			source: h3Icon("white"),
			newHoverTip: <WhiteHoverTip text="Medium Heading" />,
		},
		{
			active: () => active.heading({ level: 4 }),
			handler: () => handler(4),
			source: h4Icon("white"),
			newHoverTip: <WhiteHoverTip text="Small Heading" />,
		},
	];

	let activeTool = items[0];
	[1, 2, 3, 4].forEach((level) => {
		if (active.heading({ level })) {
			activeTool = items[level];
		}
	});

	return (
		<div className="BlockTypeDropdown">
			<ToolbarButton
				active={() => activeTool.active()}
				disabled={props.disabled}
				handler={() => {}}
				source={activeTool.source}
				newHoverTip={<WhiteHoverTip text="Heading" />}
				dropdownMenu={items.map((x) => {
					return {
						active: x.active,
						handler: x.handler,
						source: x.source,
						newHoverTip: x.newHoverTip,
					};
				})}
			/>
		</div>
	);
};
export default BlockTypeDropdown;
