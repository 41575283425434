const redoIcon = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 6.25H15.6156L13.3737 4.00875L14.25 3.125L18 6.875L14.25 10.625L13.3737 9.74062L15.6137 7.5H8C7.00544 7.5 6.05161 7.89509 5.34835 8.59835C4.64509 9.30161 4.25 10.2554 4.25 11.25C4.25 12.2446 4.64509 13.1984 5.34835 13.9017C6.05161 14.6049 7.00544 15 8 15H13V16.25H8C6.67392 16.25 5.40215 15.7232 4.46447 14.7855C3.52678 13.8479 3 12.5761 3 11.25C3 9.92392 3.52678 8.65215 4.46447 7.71447C5.40215 6.77678 6.67392 6.25 8 6.25Z"
				fill={color}
			/>
		</svg>
	);
};

export default redoIcon;
