const editorUnderline = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3 16.25H18V17.5H3V16.25ZM10.5 14.375C9.33968 14.375 8.22688 13.9141 7.40641 13.0936C6.58594 12.2731 6.125 11.1603 6.125 10V3.125H7.375V10C7.375 10.8288 7.70424 11.6237 8.29029 12.2097C8.87634 12.7958 9.6712 13.125 10.5 13.125C11.3288 13.125 12.1237 12.7958 12.7097 12.2097C13.2958 11.6237 13.625 10.8288 13.625 10V3.125H14.875V10C14.875 11.1603 14.4141 12.2731 13.5936 13.0936C12.7731 13.9141 11.6603 14.375 10.5 14.375Z"
				fill={color}
			/>
		</svg>
	);
};

export default editorUnderline;
