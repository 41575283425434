const h2Icon = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.48 15V4.92H1.726V9.365H7.032V4.92H8.278V15H7.032V10.548H1.726V15H0.48ZM12.7645 14.993L12.7715 13.873L17.2445 9.848C17.6178 9.512 17.8675 9.19467 17.9935 8.896C18.1241 8.59267 18.1895 8.28233 18.1895 7.965C18.1895 7.57767 18.1008 7.22767 17.9235 6.915C17.7461 6.60233 17.5035 6.355 17.1955 6.173C16.8921 5.991 16.5468 5.9 16.1595 5.9C15.7581 5.9 15.4011 5.99567 15.0885 6.187C14.7758 6.37367 14.5285 6.62333 14.3465 6.936C14.1691 7.24867 14.0828 7.58933 14.0875 7.958H12.8135C12.8135 7.32333 12.9605 6.76333 13.2545 6.278C13.5485 5.788 13.9475 5.40533 14.4515 5.13C14.9601 4.85 15.5365 4.71 16.1805 4.71C16.8058 4.71 17.3658 4.85467 17.8605 5.144C18.3551 5.42867 18.7448 5.81833 19.0295 6.313C19.3188 6.803 19.4635 7.35833 19.4635 7.979C19.4635 8.41767 19.4075 8.798 19.2955 9.12C19.1881 9.442 19.0201 9.74067 18.7915 10.016C18.5628 10.2867 18.2758 10.576 17.9305 10.884L14.2135 14.223L14.0525 13.803H19.4635V14.993H12.7645Z"
				fill={color}
			/>
		</svg>
	);
};

export default h2Icon;
