import copyIcon from "../../../../../assets/svgs/copyIcon";
import { replaceIcon } from "../../../../../assets/svgs/replaceIcon";
import { flyingSendIcon } from "../../../../../assets/svgs/flyingSendIcon";
import { useEffect, useRef, useState } from "react";
import { useCommands, useEditorView } from "@remirror/react";
import { useAppSelector } from "../../../../../redux";
import {
	selectAnalytics,
	selectSaver,
} from "../../../../../redux/slices/backendSlice";
import { TextSelection } from "@remirror/pm/state";
import LoadingGif from "@gigauser/common/src/assets/gifs/ai_rewrite_loader.webp";
import Clickout from "../../../../../layouts/Clickout/Clickout";
import inlineAi from "@gigauser/common/src/assets/icons/inline_ai.webp";
import Icon from "../../../../../ui/Icon/Icon";
import closeIcon from "../../../../../assets/svgs/closeIcon";
import { useToast } from "@chakra-ui/react";
import { shorterRewriteIcon } from "../../../../../assets/svgs/shorterRewrite";
import { graduationHatIcon } from "../../../../../assets/svgs/graduationHat";
import { magicWandAiIcon } from "../../../../../assets/svgs/magicWandAi";
import { loudspeakerIcon } from "../../../../../assets/svgs/loudspeakerIcon";
import { stepsIcon } from "../../../../../assets/svgs/stepsIcon";
import { chevronDown } from "../../../../../chrext-popup/assets/chevron_down";
import { Skeleton } from "../../../../../ui/skeleton/Skeleton";
import "./InlineAiRewrite.css";
import { TextEditorType } from "../../TextEditor";

type SelectionState = {
	text: string;
	position: { left: number; top: number } | null;
	range: Range | null;
	isSelectionComplete: boolean;
};

interface InlineAIPositionerProps {
	textEditorType: TextEditorType;
}

export const InlineAIPositioner: React.FC<InlineAIPositionerProps> = (
	props,
) => {
	const [selectionState, _setSelectionState] = useState<SelectionState>({
		text: "",
		position: null,
		range: null,
		isSelectionComplete: false,
	});
	const [isAiRewriteOpen, _setIsAiRewriteOpen] = useState(false);
	const view = useEditorView();
	const commands = useCommands();
	const editorRef = useRef<HTMLElement | null>(null);
	const analytics = useAppSelector(selectAnalytics);

	const setSelectionState: React.Dispatch<
		React.SetStateAction<SelectionState>
	> = (x) => {
		const hasMark: any = commands.doesDocumentHaveInlineAiMark();
		if (hasMark) {
			return;
		}
		_setSelectionState(x);
	};

	const setIsAiRewriteOpen = (x: boolean) => {
		_setIsAiRewriteOpen(x);
	};

	useEffect(() => {
		if (view) {
			editorRef.current = view.dom;
		}
	}, [view]);

	useEffect(() => {
		const checkSelection = () => {
			setSelectionState((prev) => ({
				...prev,
				isSelectionComplete: false,
			}));

			const selection = window.getSelection();

			if (!selection || selection.rangeCount === 0) {
				setSelectionState({
					text: "",
					position: null,
					range: null,
					isSelectionComplete: false,
				});
				return;
			}

			const range = selection.getRangeAt(0);
			const selectedText = selection.toString().trim();

			const editorElement = editorRef.current;

			if (!selectedText || !editorElement) {
				setSelectionState({
					text: "",
					position: null,
					range: null,
					isSelectionComplete: false,
				});
				return;
			}

			const isWithinContainer = (
				node: Node,
				className: string,
			): boolean => {
				let current = node.parentElement;
				while (current) {
					if (current.classList.contains(className)) {
						return true;
					}
					current = current.parentElement;
				}
				return false;
			};

			//check whether text is inside the editor
			const selectionWithinContainer =
				isWithinContainer(
					range.startContainer,
					"TextEditorContainer",
				) &&
				isWithinContainer(range.endContainer, "TextEditorContainer");

			if (!selectionWithinContainer) {
				setSelectionState({
					text: "",
					position: null,
					range: null,
					isSelectionComplete: false,
				});
				return;
			}

			// check whether text is not in toolbar
			const isWithinToolbar =
				isWithinContainer(
					range.startContainer,
					"gigauser-RichTextToolbar",
				) &&
				isWithinContainer(
					range.endContainer,
					"gigauser-RichTextToolbar",
				);

			if (isWithinToolbar) {
				setSelectionState({
					text: "",
					position: null,
					range: null,
					isSelectionComplete: false,
				});
				return;
			}

			const hasValidTextContent = (range: Range): boolean => {
				let hasNonCodeText = false;
				let foundText = false;

				const processNode = (node: any) => {
					let parent = node.parentElement;
					let isInCodeBlock = false;

					while (parent) {
						// this is to detect code blocks
						if (
							parent.getAttribute("data-node-type") ===
								"codeBlock" ||
							parent.tagName === "PRE" ||
							parent.tagName === "CODE"
						) {
							isInCodeBlock = true;
							break;
						}

						// this checks whether the element we have selected is actually the phantom placeholder element which gets
						// selected when you use command + a to select text in the editor
						if (parent.getAttribute("aria-placeholder") !== null) {
							isInCodeBlock = false;
							break;
						}
						parent = parent.parentElement;
					}

					// node types are the types assigned to the nodes in the editor for example text, image codeBlock etc.
					// i was not able to find them in docs i had to print them to console to see them
					if (
						(node.nodeType === 3 && node.nodeValue?.trim()) ||
						node.nodeType === 1
					) {
						foundText = true;
						if (!isInCodeBlock) {
							hasNonCodeText = true;
						}
					}
				};

				processNode(range.startContainer);

				if (range.startContainer !== range.endContainer) {
					processNode(range.endContainer);
				}

				return foundText ? hasNonCodeText : false;
			};

			if (!hasValidTextContent(range)) {
				setSelectionState({
					text: "",
					position: null,
					range: null,
					isSelectionComplete: false,
				});
				return;
			}

			// get accurate position of selection
			const rect = range.getBoundingClientRect();

			// for multi-line selections, use the last line's position
			const rects = range.getClientRects();
			const lastRect = rects[rects.length - 1];

			setSelectionState({
				text: selectedText,
				position: {
					left: lastRect ? lastRect.left : rect.left,
					top: lastRect ? lastRect.bottom : rect.bottom,
				},
				range: range,
				isSelectionComplete: false,
			});
		};

		const handleSelectionComplete = (e: MouseEvent | KeyboardEvent) => {
			// short timeout to ensure the selection is complete
			setTimeout(() => {
				const selection = window.getSelection();
				if (selection?.toString().trim()) {
					setSelectionState((prev) => ({
						...prev,
						isSelectionComplete: true,
					}));
				}
			}, 10);
		};

		commands.removeAllInlineAiMarks();
		document.addEventListener("selectionchange", checkSelection);
		document.addEventListener("mouseup", handleSelectionComplete);
		document.addEventListener("keyup", handleSelectionComplete);

		//preloading gif and logo
		const loadingGif = new Image();
		loadingGif.src = LoadingGif;

		const inlineAiLogo = new Image();
		inlineAiLogo.src = inlineAi;

		return () => {
			document.removeEventListener("selectionchange", checkSelection);
			document.removeEventListener("mouseup", handleSelectionComplete);
		};
	}, []);

	const setNativeSelection = (selection: TextSelection) => {
		const range = document.createRange();
		const from = selection.$from.pos;
		const to = selection.$to.pos;

		const domFrom = view.domAtPos(from);
		const domTo = view.domAtPos(to);

		range.setStart(domFrom.node, domFrom.offset);
		range.setEnd(domTo.node, domTo.offset);

		const nativeSelection = window.getSelection();
		if (!nativeSelection) return;

		nativeSelection.removeAllRanges();
		nativeSelection.addRange(range);
	};

	const handleTextMutation = async (e: React.MouseEvent, newText: string) => {
		e.preventDefault();
		e.stopPropagation();

		if (!selectionState.text || !selectionState.range || !view) return;

		try {
			const doc = view.state.doc;
			let fromPos: number | null = null;
			let toPos: number | null = null;

			// loop through the doc to find nodes with ai inline mark
			doc.descendants((node, pos) => {
				if (node.marks.find((mark) => mark.type.name === "inline-ai")) {
					if (fromPos === null) fromPos = pos;
					// add node size to get the end position of the marked text
					toPos = pos + node.nodeSize;
				}
			});

			if (fromPos === null || toPos === null) {
				console.error("Could not find marked text position");
				return;
			}

			//this needs to be before the replacement to make sure it doesnt get clipped as part of the
			//transaction which involves replacing the text

			const textReplacementTr = view.state.tr.replaceWith(
				fromPos,
				toPos,
				view.state.schema.text(newText),
			);
			view.dispatch(textReplacementTr);

			const newSelection = TextSelection.create(
				view.state.doc,
				fromPos,
				fromPos + newText.length,
			);
			const selectionUpdateTr = view.state.tr.setSelection(newSelection);
			view.dispatch(selectionUpdateTr);

			// Clean up
			const emptyTr = view.state.tr;
			emptyTr.setMeta("addToHistory", false);
			view.dispatch(emptyTr);
			commands.removeAllInlineAiMarks();
			setSelectionState({
				text: "",
				position: null,
				range: null,
				isSelectionComplete: false,
			});
			setIsAiRewriteOpen(false);

			setNativeSelection(
				TextSelection.create(
					view.state.doc,
					view.state.selection.from,
					view.state.selection.to,
				),
			);
		} catch (error) {
			console.error("Error replacing text:", error);
		}
	};

	if (
		!selectionState.position ||
		!selectionState.text ||
		!selectionState.isSelectionComplete
	) {
		if (isAiRewriteOpen) setIsAiRewriteOpen(false);
		return null;
	}

	const buttonsPanelsContainerElement = document.querySelectorAll(
		".ButtonsPanel-container",
	)[0];
	const editorTopBarElement = document.querySelectorAll(
		".EditorPlatform-topbar",
	)[0];

	const openAiRewrite = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		if (isAiRewriteOpen) return;
		commands.setInlineAiMark(view.state.selection);
		commands.emptySelection();
		setIsAiRewriteOpen(true);
		analytics.captureEvent({
			eventName: "InlineAiUiTriggered",
			value: {},
		});
	};

	return (
		<Clickout closeFunction={() => {}}>
			<div
				className={`inline-ai-positioner ${isAiRewriteOpen ? "expanded" : ""}`}
				onMouseDown={(e) => {
					openAiRewrite(e);
				}}
				style={{
					position: "absolute",
					zIndex: 10000000,
					top:
						props.textEditorType === "platform"
							? isAiRewriteOpen
								? Math.min(
										selectionState.position.top - 64 + 10,
										window.innerHeight * 0.8 - 300,
									)
								: selectionState.position.top - 64 + 10
							: selectionState.position.top +
								10 -
								editorTopBarElement.clientHeight,
					left:
						props.textEditorType === "platform"
							? isAiRewriteOpen
								? Math.min(
										selectionState.position.left,
										window.innerWidth * 0.65 - 700,
									)
								: Math.min(
										selectionState.position.left - 110,
										window.innerWidth * 0.65 - 300,
									)
							: selectionState.position.left -
								buttonsPanelsContainerElement.clientWidth,
					paddingLeft: !isAiRewriteOpen ? "0.533rem" : "1.06rem",
					paddingRight: "1.06rem",
					paddingTop: !isAiRewriteOpen ? "0.533rem" : "1.06rem",
					paddingBottom: !isAiRewriteOpen ? "0.533rem" : "1.06rem",
					backgroundColor: isAiRewriteOpen ? "#13151B" : undefined,
				}}
			>
				<div
					style={{
						marginBottom: isAiRewriteOpen ? "1.06rem" : undefined,
					}}
					className="inline-ai-top-row"
				>
					<div style={{ display: "inherit", gap: "0.533rem" }}>
						<img className="inline-ai-icon" src={inlineAi}></img>
						<p
							style={{
								fontSize: isAiRewriteOpen
									? "1.06rem"
									: "0.933rem",
							}}
							className="inline-ai-heading"
						>
							Improve with AI
						</p>
					</div>
					{isAiRewriteOpen && (
						<Icon
							onClick={() => {
								commands.removeAllInlineAiMarks();
								setSelectionState({
									text: "",
									position: null,
									range: null,
									isSelectionComplete: false,
								});
								setIsAiRewriteOpen(false);
							}}
							className="close-icon"
						>
							{closeIcon("#888B9C")}
						</Icon>
					)}
				</div>
				{isAiRewriteOpen ? (
					<InlineAIRewrite
						initialText={selectionState.text}
						handleTextMutation={handleTextMutation}
						closeFunction={() => {
							commands.removeAllInlineAiMarks();
							setSelectionState({
								text: "",
								position: null,
								range: null,
								isSelectionComplete: false,
							});
							setIsAiRewriteOpen(false);
						}}
					/>
				) : null}
			</div>
		</Clickout>
	);
};

type PromptState = "CustomizeState" | "OptionSelectionState" | null;
type InlineAIRewriteProps = {
	initialText: string;
	handleTextMutation: (e: React.MouseEvent, newText: string) => void;
	closeFunction: () => void;
};

const InlineAIRewrite: React.FC<InlineAIRewriteProps> = (props) => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const premadePrompts = [
		[
			{
				icon: shorterRewriteIcon(),
				text: "Make shorter",
				prompt: "Summarize the text and remove any redundant details.",
			},
			{
				icon: graduationHatIcon(),
				text: "More formal",
				prompt: "Rewrite the text in a formal, business-like tone.",
			},
			{
				icon: magicWandAiIcon(),
				text: "Simplify text",
				prompt: "Rewrite this text using simple, clear language suitable for beginners.",
			},
		],
		[
			{
				icon: loudspeakerIcon(),
				text: "Adapt for marketing",
				prompt: "Rewrite the text with an upbeat marketing tone to highlight benefits.",
			},
			{
				icon: magicWandAiIcon(),
				text: "Add more detail",
				prompt: "Expand the text to include more details and examples.",
			},
			{
				icon: stepsIcon(),
				text: "Convert to steps",
				prompt: "Rewrite this text as a step-by-step guide.",
			},
		],
	];

	const [promptState, setPromptState] = useState<PromptState>(null);
	const [isLoading, setIsLoading] = useState(false);
	const aiResults = useRef<string[]>([]);
	const saver = useAppSelector(selectSaver);
	const prevAction = useRef<string[]>([]);
	const [selectedAiResult, setSelectedAiResult] = useState(0);
	const toast = useToast();
	const [inputText, setInputText] = useState("");
	const analytics = useAppSelector(selectAnalytics);

	const getAiResult = async (prompt: string, action: string) => {
		prevAction.current.push(action);
		setIsLoading(true);

		let text =
			aiResults.current.length > 0
				? aiResults.current[0]
				: props.initialText;

		if (text[0] === `"`) {
			text = text.slice(1);
		}

		if (text[text.length - 1] === `"`) {
			text = text.slice(0, -1);
		}
		const resp = await saver.guides.inlineEnhance(text, prompt);
		if (resp.ok) {
			aiResults.current.push(resp.body);
			setSelectedAiResult(aiResults.current.length - 1);
			setIsLoading(false);
		} else {
			prevAction.current.pop();
			setIsLoading(false);
			if (resp.error.error === "UsageLimitExceededError") {
				props.closeFunction();
			} else {
				analytics.captureEvent({
					eventName: "ErrorInlineAiTriggered",
					value: {
						prompt: inputText,
					},
				});
				props.closeFunction();
				toast({
					title: "Error enhancing text",
					description: "Please try again later.",
					status: "error",
					duration: 4000,
					isClosable: true,
					position: "top",
				});
			}
		}
	};

	const handleSubmitOrChipClick = (prompt: string, action: string) => {
		getAiResult(prompt, action);
		setPromptState("OptionSelectionState");
	};

	const tryAgainHandler = () => {
		setPromptState("OptionSelectionState");
		getAiResult("Try again", "Try again");
	};

	const customizeHandler = () => {
		setPromptState("CustomizeState");
	};

	const changeAiResult = (direction: "next" | "prev") => {
		if (direction === "next") {
			if (selectedAiResult < aiResults.current.length - 1) {
				setSelectedAiResult(selectedAiResult + 1);
			}
		} else {
			if (selectedAiResult > 0) {
				setSelectedAiResult(selectedAiResult - 1);
			}
		}
	};

	const handleEnterKey = (e: KeyboardEvent) => {
		if (e.key === "Enter" && inputText.length > 0) {
			analytics.captureEvent({
				eventName: "InlineAiTriggered",
				value: {
					prompt: inputText,
					premadePrompt: false,
				},
			});
			handleSubmitOrChipClick(inputText, inputText);
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleEnterKey);
		return () => {
			document.removeEventListener("keydown", handleEnterKey);
		};
	}, [inputText]);

	return (
		<div className="inline-ai-container">
			<div className="inline-ai-line"></div>
			<div className="scroll-container">
				{promptState === null && (
					<div className="premade-prompt-grid">
						{premadePrompts.map((row, i) => (
							<div className="premade-prompt-row" key={i}>
								{row.map((prompt, j) => (
									<div
										onClick={() => {
											analytics.captureEvent({
												eventName: "InlineAiTriggered",
												value: {
													prompt: prompt.prompt,
													premadePrompt: true,
												},
											});
											handleSubmitOrChipClick(
												prompt.prompt,
												prompt.text,
											);
										}}
										className="premade-prompt"
										key={j}
									>
										<Icon className="prompt-icon">
											{prompt.icon}
										</Icon>
										<p className="premade-prompt-text">
											{prompt.text}
										</p>
									</div>
								))}
							</div>
						))}
					</div>
				)}
				{promptState !== null ? (
					<div>
						<div className="option-select-heading-container">
							{isLoading ? (
								<img
									className="loading-gif"
									src={LoadingGif}
								></img>
							) : prevAction.current === null ||
							  aiResults.current.length === 1 ? (
								<Icon className="magic-wand-icon">
									{magicWandAiIcon()}
								</Icon>
							) : (
								<div className="ai-result-selector">
									<Icon
										className={`ai-result-selector-icon-left ${selectedAiResult > 0 ? "" : "disabled"}`}
										onClick={() => changeAiResult("prev")}
									>
										{chevronDown(
											selectedAiResult > 0
												? "white"
												: "grey",
										)}
									</Icon>
									{`${selectedAiResult + 1}/${aiResults.current.length}`}
									<Icon
										onClick={() => changeAiResult("next")}
										className={`ai-result-selector-icon-right ${selectedAiResult < aiResults.current.length - 1 ? "" : "disabled"}`}
									>
										{chevronDown(
											selectedAiResult <
												aiResults.current.length - 1
												? "white"
												: "grey",
										)}
									</Icon>
								</div>
							)}
							<p
								className={`rewrite-with-ai-heading ${isLoading ? "loading" : ""}`}
							>
								{
									<span>
										{prevAction.current[
											isLoading
												? selectedAiResult + 1
												: selectedAiResult
										] ??
											prevAction.current[
												prevAction.current.length - 1
											]}
									</span>
								}
							</p>
						</div>
						{isLoading ? (
							<div className="inline-ai-text-skeleton">
								<Skeleton
									width="31.2rem"
									height="0.933rem"
									baseColor="#212433"
									shimmerGradient={
										"linear-gradient(90deg, transparent 0%,#424559 51%, transparent 100%)"
									}
									variant="rounded"
									sheenWidth={"3rem"}
									animationDuration={0.8}
								></Skeleton>
								<Skeleton
									width="20.6rem"
									height="0.933rem"
									baseColor="#212433"
									shimmerGradient={
										"linear-gradient( 90deg, transparent 0%, #424559 51%, transparent 100%)"
									}
									variant="rounded"
									sheenWidth={"3rem"}
									animationDuration={0.8}
								></Skeleton>
							</div>
						) : (
							<div className="ai-result-container">
								<p className="ai-result">
									"{aiResults.current[selectedAiResult]}"
								</p>
							</div>
						)}
					</div>
				) : null}
			</div>
			<div className="line"></div>
			<div className="bottom-row">
				{promptState !== "OptionSelectionState" ? (
					<div
						style={{
							display: "inherit",
							width: "100%",
							height: "100%",
							gap: "0.533rem",
						}}
					>
						<div
							className={`prompt-input-border ${inputRef.current?.contains(document.activeElement) ? "focused" : ""}`}
						>
							<input
								ref={inputRef}
								onChange={(e) => {
									setInputText(e.target.value);
								}}
								onClick={() => {
									inputRef.current?.focus();
								}}
								autoFocus
								className={`prompt-input`}
								placeholder={
									promptState === null
										? "Add instructions"
										: "Customize"
								}
							></input>
						</div>
						<div
							onClick={() => {
								if (inputRef.current?.value) {
									analytics.captureEvent({
										eventName: "InlineAiTriggered",
										value: {
											prompt: inputText,
											premadePrompt: false,
										},
									});
									handleSubmitOrChipClick(
										inputText,
										inputText,
									);
								}
							}}
							className={`inline-ai-submit-button ${inputText.length > 0 ? "" : "disabled"}`}
						>
							Submit{" "}
							{
								<Icon className="submit-btn-icon">
									{flyingSendIcon()}
								</Icon>
							}
						</div>
					</div>
				) : (
					<div className="btn-group-container">
						<div className="btn-group">
							<div
								onClick={tryAgainHandler}
								className={`try-again-btn ${isLoading ? "disabled" : ""}`}
							>
								Try again
							</div>
							<div
								onClick={customizeHandler}
								className={`customize-btn ${isLoading ? "disabled" : ""}`}
							>
								Customize
							</div>
						</div>
						<div className="btn-group">
							<div
								onClick={() => {
									navigator.clipboard.writeText(
										aiResults.current[selectedAiResult],
									);
									toast({
										title: "Copied text to clipboard",
										status: "success",
										duration: 4000,
										isClosable: true,
										position: "top",
									});
								}}
								className={`copy-ai-btn ${isLoading ? "disabled" : ""}`}
							>
								{
									<Icon className="copy-icon">
										{copyIcon(
											isLoading
												? "rgba(250, 251, 255, 0.20)"
												: "white",
										)}
									</Icon>
								}
							</div>
							<div
								onClick={(e: React.MouseEvent) => {
									props.handleTextMutation(
										e,
										aiResults.current[selectedAiResult],
									);
									analytics.captureEvent({
										eventName: "InlineAiReplaced",
										value: {
											prompt:
												prevAction.current[
													selectedAiResult
												] ??
												prevAction.current[
													prevAction.current.length -
														1
												],
										},
									});
								}}
								className={`replace-btn ${isLoading ? "disabled" : ""}`}
							>
								{
									<Icon className="replace-icon">
										{replaceIcon()}
									</Icon>
								}
								Replace
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
