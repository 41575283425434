const undoIcon = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13 6.25H5.38438L7.62625 4.00875L6.75 3.125L3 6.875L6.75 10.625L7.62625 9.74062L5.38625 7.5H13C13.9946 7.5 14.9484 7.89509 15.6517 8.59835C16.3549 9.30161 16.75 10.2554 16.75 11.25C16.75 12.2446 16.3549 13.1984 15.6517 13.9017C14.9484 14.6049 13.9946 15 13 15H8V16.25H13C14.3261 16.25 15.5979 15.7232 16.5355 14.7855C17.4732 13.8479 18 12.5761 18 11.25C18 9.92392 17.4732 8.65215 16.5355 7.71447C15.5979 6.77678 14.3261 6.25 13 6.25Z"
				fill={color}
			/>
		</svg>
	);
};

export default undoIcon;
