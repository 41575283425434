const h3Icon = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.48 15V4.92H1.726V9.365H7.032V4.92H8.278V15H7.032V10.548H1.726V15H0.48ZM15.8095 15.196C15.3101 15.196 14.8458 15.105 14.4165 14.923C13.9871 14.7363 13.6161 14.475 13.3035 14.139C12.9955 13.7983 12.7691 13.3947 12.6245 12.928L13.8215 12.585C13.9755 13.0517 14.2321 13.4063 14.5915 13.649C14.9508 13.8917 15.3545 14.0107 15.8025 14.006C16.2131 13.9967 16.5678 13.9033 16.8665 13.726C17.1698 13.544 17.4031 13.2967 17.5665 12.984C17.7345 12.6713 17.8185 12.3097 17.8185 11.899C17.8185 11.2737 17.6318 10.7673 17.2585 10.38C16.8851 9.988 16.3951 9.792 15.7885 9.792C15.6205 9.792 15.4431 9.81533 15.2565 9.862C15.0745 9.90867 14.9041 9.974 14.7455 10.058L14.1435 9.071L17.8815 5.69L18.0425 6.11H13.0235V4.92H18.9665V6.117L15.7815 9.169L15.7675 8.728C16.4348 8.68133 17.0181 8.791 17.5175 9.057C18.0168 9.323 18.4041 9.70333 18.6795 10.198C18.9595 10.6927 19.0995 11.2597 19.0995 11.899C19.0995 12.543 18.9548 13.1123 18.6655 13.607C18.3761 14.1017 17.9841 14.4913 17.4895 14.776C16.9948 15.056 16.4348 15.196 15.8095 15.196Z"
				fill={color}
			/>
		</svg>
	);
};

export default h3Icon;
