const editorBold = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.9062 15.625H6.125V4.375H11.4375C12.0639 4.37504 12.6771 4.55435 13.2048 4.89174C13.7325 5.22914 14.1526 5.71052 14.4155 6.27903C14.6784 6.84754 14.7731 7.47942 14.6884 8.10001C14.6037 8.72061 14.3431 9.30399 13.9375 9.78125C14.4673 10.205 14.8528 10.7825 15.0408 11.4344C15.2289 12.0862 15.2102 12.7803 14.9875 13.4211C14.7647 14.0619 14.3488 14.6179 13.797 15.0126C13.2452 15.4073 12.5847 15.6213 11.9062 15.625ZM8 13.75H11.8937C12.0784 13.75 12.2613 13.7136 12.4319 13.643C12.6025 13.5723 12.7575 13.4687 12.8881 13.3381C13.0187 13.2075 13.1223 13.0525 13.193 12.8819C13.2636 12.7113 13.3 12.5284 13.3 12.3438C13.3 12.1591 13.2636 11.9762 13.193 11.8056C13.1223 11.635 13.0187 11.48 12.8881 11.3494C12.7575 11.2188 12.6025 11.1152 12.4319 11.0445C12.2613 10.9739 12.0784 10.9375 11.8937 10.9375H8V13.75ZM8 9.0625H11.4375C11.6222 9.0625 11.805 9.02613 11.9756 8.95546C12.1463 8.88478 12.3013 8.7812 12.4319 8.65062C12.5625 8.52004 12.666 8.36501 12.7367 8.1944C12.8074 8.02378 12.8438 7.84092 12.8438 7.65625C12.8438 7.47158 12.8074 7.28872 12.7367 7.1181C12.666 6.94749 12.5625 6.79246 12.4319 6.66188C12.3013 6.5313 12.1463 6.42772 11.9756 6.35704C11.805 6.28637 11.6222 6.25 11.4375 6.25H8V9.0625Z"
				fill={color}
			/>
		</svg>
	);
};

export default editorBold;
