const editorStrikethrough = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18 9.37538H11.7225C11.445 9.30076 11.1662 9.23096 10.8863 9.16601C9.13125 8.75101 8.13875 8.44726 8.13875 7.02663C8.1245 6.78139 8.16081 6.53584 8.24542 6.30522C8.33004 6.0746 8.46115 5.86384 8.63062 5.68601C9.1615 5.24944 9.82644 5.00889 10.5137 5.00476C12.2825 4.96101 13.0981 5.56101 13.765 6.47351L14.7744 5.73601C14.3019 5.05748 13.6578 4.51654 12.9078 4.16845C12.1578 3.82036 11.3288 3.6776 10.5056 3.75476C9.49439 3.76121 8.51887 4.12947 7.75563 4.79288C7.46634 5.08632 7.24024 5.4359 7.09125 5.82008C6.94227 6.20426 6.87356 6.61488 6.88937 7.02663C6.86197 7.47718 6.9466 7.92751 7.13572 8.33737C7.32483 8.74723 7.61254 9.10385 7.97312 9.37538H3V10.6254H11.5325C12.7619 10.9816 13.4969 11.4454 13.5156 12.7241C13.5359 12.9973 13.4985 13.2717 13.4056 13.5294C13.3128 13.7871 13.1667 14.0223 12.9769 14.2198C12.3155 14.7411 11.4938 15.017 10.6519 15.0004C10.0234 14.9822 9.40738 14.8213 8.85029 14.5299C8.2932 14.2385 7.80966 13.8243 7.43625 13.3185L6.47812 14.121C6.96358 14.768 7.58994 15.2959 8.30972 15.6648C9.02951 16.0338 9.82384 16.234 10.6325 16.2504H10.695C11.8492 16.2636 12.9695 15.86 13.85 15.1135C14.1625 14.7984 14.4054 14.4213 14.5632 14.0065C14.7209 13.5917 14.7898 13.1485 14.7656 12.7054C14.789 11.9474 14.5332 11.2072 14.0469 10.6254H18V9.37538Z"
				fill={color}
			/>
		</svg>
	);
};

export default editorStrikethrough;
