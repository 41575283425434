const link = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.7813 4.22405C18.4329 3.87445 18.0189 3.59706 17.5631 3.40779C17.1073 3.21852 16.6186 3.12109 16.125 3.12109C15.6315 3.12109 15.1428 3.21852 14.687 3.40779C14.2312 3.59706 13.8172 3.87445 13.4688 4.22405L14.3563 5.11155C14.589 4.87886 14.8652 4.69429 15.1692 4.56836C15.4733 4.44243 15.7991 4.37762 16.1282 4.37762C16.4572 4.37762 16.7831 4.44243 17.0871 4.56836C17.3911 4.69429 17.6674 4.87886 17.9 5.11155C18.1327 5.34424 18.3173 5.62047 18.4432 5.92449C18.5692 6.22851 18.634 6.55436 18.634 6.88343C18.634 7.21249 18.5692 7.53834 18.4432 7.84236C18.3173 8.14638 18.1327 8.42261 17.9 8.6553L12.9 13.6553C12.4309 14.1252 11.7944 14.3896 11.1304 14.3901C10.4664 14.3907 9.82935 14.1275 9.35942 13.6584C8.88949 13.1893 8.62515 12.5528 8.62457 11.8888C8.62398 11.2248 8.88719 10.5877 9.35629 10.1178L10.2375 9.2303L9.35629 8.3428L8.46879 9.2303C8.11919 9.57869 7.8418 9.99266 7.65253 10.4485C7.46326 10.9043 7.36583 11.393 7.36583 11.8865C7.36583 12.3801 7.46326 12.8688 7.65253 13.3246C7.8418 13.7804 8.11919 14.1944 8.46879 14.5428C9.17597 15.2409 10.1313 15.6298 11.125 15.624C11.6205 15.6261 12.1114 15.5299 12.5695 15.3411C13.0276 15.1523 13.4437 14.8746 13.7938 14.524L18.7938 9.52405C19.4944 8.81927 19.8866 7.86522 19.8842 6.87146C19.8819 5.87771 19.4852 4.92552 18.7813 4.22405Z"
				fill={color}
			/>
			<path
				d="M3.11879 15.5115C2.88541 15.2793 2.70022 15.0031 2.57386 14.6991C2.44749 14.395 2.38244 14.069 2.38244 13.7397C2.38244 13.4104 2.44749 13.0844 2.57386 12.7803C2.70022 12.4762 2.88541 12.2001 3.11879 11.9678L8.11879 6.9678C8.35109 6.73442 8.6272 6.54923 8.93127 6.42287C9.23534 6.2965 9.56138 6.23145 9.89067 6.23145C10.2199 6.23145 10.546 6.2965 10.8501 6.42287C11.1541 6.54923 11.4302 6.73442 11.6625 6.9678C11.8944 7.20193 12.077 7.48022 12.1994 7.78619C12.3218 8.09215 12.3815 8.41958 12.375 8.74905C12.3769 9.07955 12.3133 9.40715 12.1878 9.7129C12.0623 10.0186 11.8774 10.2965 11.6438 10.5303L10.3188 11.8741L11.2063 12.7615L12.5313 11.4365C13.2366 10.7312 13.6328 9.77463 13.6328 8.77718C13.6328 7.77972 13.2366 6.82311 12.5313 6.1178C11.826 5.41249 10.8694 5.01625 9.87192 5.01625C8.87446 5.01625 7.91785 5.41249 7.21254 6.1178L2.21254 11.1178C1.862 11.4663 1.58382 11.8807 1.39399 12.3371C1.20417 12.7935 1.10645 13.2829 1.10645 13.7772C1.10645 14.2715 1.20417 14.7609 1.39399 15.2173C1.58382 15.6737 1.862 16.088 2.21254 16.4365C2.92431 17.1293 3.88185 17.5115 4.87504 17.499C5.87698 17.5 6.83862 17.1046 7.55004 16.399L6.66254 15.5115C6.43025 15.7449 6.15413 15.9301 5.85006 16.0565C5.54599 16.1829 5.21995 16.2479 4.89067 16.2479C4.56138 16.2479 4.23534 16.1829 3.93127 16.0565C3.6272 15.9301 3.35109 15.7449 3.11879 15.5115Z"
				fill={color}
			/>
		</svg>
	);
};

export default link;
