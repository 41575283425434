const ulLight = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.875 7.5C5.91053 7.5 6.75 6.66053 6.75 5.625C6.75 4.58947 5.91053 3.75 4.875 3.75C3.83947 3.75 3 4.58947 3 5.625C3 6.66053 3.83947 7.5 4.875 7.5Z"
				fill={color}
			/>
			<path
				d="M4.875 16.25C5.91053 16.25 6.75 15.4105 6.75 14.375C6.75 13.3395 5.91053 12.5 4.875 12.5C3.83947 12.5 3 13.3395 3 14.375C3 15.4105 3.83947 16.25 4.875 16.25Z"
				fill={color}
			/>
			<path
				d="M10.5 13.75H19.25V15H10.5V13.75ZM10.5 5H19.25V6.25H10.5V5Z"
				fill={color}
			/>
		</svg>
	);
};

export default ulLight;
