import React, { MouseEventHandler, useContext, useState } from "react";
// import folder_color from '../../../../../../../assets/icons/folder_color.svg'
// import right_arrow from '../../../../../../../assets/icons/right_arrow.svg'

import FolderAccordion from "./FolderAccordion";
import "./FolderAccordion.css";
import { Collection } from "@gigauser/common/src/core/types/collections";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import { rootCollection } from "@gigauser/common/src/types/files";
import {
	CollectionNode,
	updateNodeInTree,
} from "../../../../redux/slices/hcSlice";
import folder_color from "@gigauser/common/src/assets/svgs/folder_color";
import downArrowheadIcon from "@gigauser/common/src/assets/svgs/downArrowheadIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import useGetSubtree from "../hooks/useGetSubtree";
import useOpenCollection from "../../../../hooks/useOpenCollection";

type FolderSectionProps = {
	node: CollectionNode;
	parentSegments: Collection[];
};

const FolderSectionComponent: React.FC<FolderSectionProps> = ({
	node,
	parentSegments,
}) => {
	const [isFolderOpened, setIsFolderOpened] = useState(false);

	// const navigate = useFolderNavigate(env)
	// const folderPath = useAppSelector(selectFolderPath)
	const folderPath = useAppSelector((state) => state.hc.collectionPath);
	const currCollection = folderPath?.at(-1);
	const currentGuide = useAppSelector((state) => state.hc.currentGuide);
	const openCollection = useOpenCollection();

	const getSubtree = useGetSubtree();

	const dispatch = useAppDispatch();

	const objectPath = parentSegments.concat([node.collection]) as [
		Collection,
		...Collection[],
	];

	const onFolderNameClick: MouseEventHandler = (e) => {};

	const collapseCollectionNode = async (collapse: boolean) => {
		if (!collapse) {
			//we need to expand the tree at this node
			var nodeCopy = { ...node };
			nodeCopy.collapsed = false;

			dispatch(updateNodeInTree(nodeCopy));

			if (node.children) {
				//This node was already loaded before
				return;
			}

			// Some hook to fetch data

			const subtree = await getSubtree(nodeCopy.collection.id);

			var finalNodeCopy = { ...nodeCopy };
			finalNodeCopy.children = subtree;

			dispatch(updateNodeInTree(finalNodeCopy));
		} else {
			const nodeCopy = { ...node };
			nodeCopy.collapsed = true;
			dispatch(updateNodeInTree(nodeCopy));
		}
	};

	const onExploreIconClicked: MouseEventHandler = async (e) => {
		// Update data once its loaded

		const collapsed = node.collapsed;
		e.preventDefault();
		e.stopPropagation();

		if (collapsed) {
			collapseCollectionNode(false);
		} else {
			collapseCollectionNode(true);
		}
	};

	const onOpenCollection = () => {
		const segments = [rootCollection, ...parentSegments];
		collapseCollectionNode(false);
		if (segments.length) {
			openCollection(
				node.collection,
				segments as [Collection, ...Collection[]],
			);
		}
	};

	const isActive = currCollection?.id === node.collection.id && !currentGuide;

	const isPrimaryCollection = node.collection.parentId ? false : true;

	const appTheme = useAppSelector((state) => state.hcUi.theme);

	var isPrivate = node.collection.private;
	parentSegments.map((collection) => {
		if (collection.private) isPrivate = true;
	});

	return (
		<div className="accordion-folder-container">
			<div
				className={`accordion-folder accordion-item ${isActive ? "accordion-active-item" : "accordion-inactive-item"}`}
				onClick={onOpenCollection}
				style={{ display: isPrivate ? "none" : "flex" }}
			>
				{/* <div className={`explore-folder-icon ${node.collapsed ? "not-explored" : "explored"}`} onClick={onExploreIconClicked}><img src={right_arrow} alt="" style={{height: "10px"}}></img></div> */}
				<div
					className={`accordion-folder-name ${isPrimaryCollection ? "primary-accordion-folder" : ""} `}
				>
					{node.collection.name}
				</div>
				<Icon
					onClick={onExploreIconClicked}
					className={`accordion-folder-icon ${node.collapsed ? "not-explored" : "explored"}`}
				>
					{downArrowheadIcon(
						appTheme === "gigauser-light" ? "#303030" : "#9ca3af",
					)}
				</Icon>
			</div>

			{!node.collapsed && node.children !== undefined ? (
				<div className="explored-folder-list">
					<FolderAccordion
						tree={node.children || []}
						parentSegments={objectPath}
					></FolderAccordion>
				</div>
			) : null}
		</div>
	);
};
export default FolderSectionComponent;
