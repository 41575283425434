const hrIcon = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="3" y="9.375" width="15" height="1.25" fill={color} />
		</svg>
	);
};

export default hrIcon;
