export const shiftIcon = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M1 10.4442L9.44418 2L17.8983 10.4442H13.6712V17.9159H5.22705V10.4442H1ZM9.44418 3.73646L3.83788 9.34276H6.44754V16.6954H12.4507V9.34276H15.0505L9.44418 3.73646Z"
				fill={color}
			/>
		</svg>
	);
};
