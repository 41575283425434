const editorCode = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.875 10L15.5 14.375L14.6187 13.4937L18.1063 10L14.6187 6.50625L15.5 5.625L19.875 10ZM1.125 10L5.5 5.625L6.38125 6.50625L2.89375 10L6.38125 13.4937L5.5 14.375L1.125 10ZM8.2625 15.9275L11.525 3.75L12.7325 4.07313L9.47 16.25L8.2625 15.9275Z"
				fill={color}
			/>
		</svg>
	);
};

export default editorCode;
