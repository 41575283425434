const centerAlign = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.75 3.75H16.25V5H3.75V3.75ZM6.25 7.5H13.75V8.75H6.25V7.5ZM3.75 11.25H16.25V12.5H3.75V11.25ZM6.25 15H13.75V16.25H6.25V15Z"
				fill={color}
			/>
		</svg>
	);
};

export default centerAlign;
