import { Node } from "@remirror/pm/model";
import {
	useActive,
	useChainedCommands,
	useCommands,
	useRemirrorContext,
} from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import { saver } from "../../../../../../network/saver";
import { useAppSelector } from "../../../../../../redux";
import { selectGuidePreview } from "../../../../../../redux/slices/guideSlice";
import { GuidePreview } from "../../../../../../core/types/guide";
import useAddScreenshot from "../../AddScreenshot/useAddScreenshot";
import "./ScreenshotDropdown.css";
import Icon from "../../../../../../ui/Icon/Icon";
import imagePlus from "../../../../../../assets/svgs/imagePlus";
import ToolbarButton from "../ToolbarButton/ToolbarButton";
import WhiteHoverTip from "../../../../../../ui/WhiteHoverTip/WhiteHoverTip";

type ScreenshotDropdownProps = {
	disabled?: boolean;
};

const ScreenshotDropdown: React.FC<ScreenshotDropdownProps> = (props) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const { commands, manager } = useRemirrorContext();
	const guidePreview = useAppSelector(selectGuidePreview) as GuidePreview;
	const originalVideo = useAppSelector(
		(state) => state.guide.value.guide?.guideData.video.originalSrc,
	) as string;
	const addScreenshot = useAddScreenshot("textEditor");
	const handleFileUpload = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const file = event.target.files?.[0];
		if (file) {
			// Call your function here with the file data
			// Example: uploadImage(file);
			// Define the command to insert the screenshot
			// Replace uri with the actual image uri

			// Function to convert blob to data uri

			//Get format of file
			const format = file.name.split(".").at(-1);
			const reader = new FileReader();
			reader.onload = async () => {
				const uri = reader.result as string;
				const resp = await saver.uploadImage(guidePreview.id, uri);
				const node: Node = manager.schema.nodes.image.create({
					src: uri,
					metadata: {
						stepId: Date.now().toString(),
						s3ObjectId: resp,
						format: format,
					},
				});
				commands.insertNode(node);
				// Now you can use the dataURI as needed, for example, setting it as the source of an image or a link.
			};

			reader.readAsDataURL(file);
		}
	};

	const items = [];

	if (originalVideo) {
		items.push({
			active: () => false,
			handler: () => {
				addScreenshot();
			},
			source: (
				<span className="ToolbarButton-dropdown-text-item">
					Choose from video
				</span>
			),
		});
	}

	items.push({
		active: () => false,
		handler: () => {
			fileInputRef.current?.click();
		},
		source: (
			<>
				<span className="ToolbarButton-dropdown-text-item">
					Upload from computer
				</span>
				<input
					type="file"
					accept="image/*"
					ref={fileInputRef}
					style={{ display: "none" }}
					onChange={handleFileUpload}
				/>
			</>
		),
	});

	return (
		<div className="ScreenshotDropdown">
			<ToolbarButton
				active={() => false}
				disabled={props.disabled}
				handler={() => {}}
				source={imagePlus("white")}
				newHoverTip={<WhiteHoverTip text="Insert Image" />}
				dropdownType="verticle"
				dropdownMenu={items.map((x) => {
					return {
						active: () => false,
						handler: x.handler,
						source: x.source,
					};
				})}
			/>
		</div>
	);
};

export default ScreenshotDropdown;
