const h1Icon = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.48 15V4.92H2.726V9.365H8.032V4.92H9.278V15H8.032V10.548H2.726V15H1.48ZM15.8015 15V6.299L13.9045 7.447V6.054L15.8015 4.92H17.0685V15H15.8015Z"
				fill={color}
			/>
		</svg>
	);
};

export default h1Icon;
