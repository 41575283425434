const olLight = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.5 13.75H19.25V15H10.5V13.75ZM10.5 5H19.25V6.25H10.5V5ZM5.5 7.5V2.5H4.25V3.125H3V4.375H4.25V7.5H3V8.75H6.75V7.5H5.5ZM6.75 17.5H3V15C3 14.6685 3.1317 14.3505 3.36612 14.1161C3.60054 13.8817 3.91848 13.75 4.25 13.75H5.5V12.5H3V11.25H5.5C5.83152 11.25 6.14946 11.3817 6.38388 11.6161C6.6183 11.8505 6.75 12.1685 6.75 12.5V13.75C6.75 14.0815 6.6183 14.3995 6.38388 14.6339C6.14946 14.8683 5.83152 15 5.5 15H4.25V16.25H6.75V17.5Z"
				fill={color}
			/>
		</svg>
	);
};

export default olLight;
