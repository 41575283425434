export enum UploadErrorCode {
	OK = "OK",
	UnauthorizedError = "UnauthorizedError",
	ActionNotPermittedError = "ActionNotPermittedError",
	UsageLimitExceededError = "UsageLimitExceededError",
	CorruptedFileError = "CorruptedFileError",
	S3UploadError = "S3UploadError",
	UnknownError = "UnknownError",
}
export enum AuthErrorCode {
	OK = "OK",
	UnauthorizedError = "UnauthorizedError",
	ActionNotPermittedError = "ActionNotPermittedError",
	UsageLimitExceededError = "UsageLimitExceededError",
	QueryFailedError = "QueryFailedError",
	UnknownError = "UnknownError",
}
export function getUploadErrorDesc(error_code: UploadErrorCode): string {
	let error_desc: string;

	switch (error_code) {
		case UploadErrorCode.OK:
			error_desc = "Operation completed successfully.";
			break;
		case UploadErrorCode.UnauthorizedError:
			error_desc = "You are unauthorized to perform this operation.";
			break;
		case UploadErrorCode.ActionNotPermittedError:
			error_desc = "You are not permitted to perform this action.";
			break;
		case UploadErrorCode.UsageLimitExceededError:
			error_desc = "You have exceeded your usage limit.";
			break;
		case UploadErrorCode.CorruptedFileError:
			error_desc =
				"There may be an issue with one or more of your files.";
			break;
		case UploadErrorCode.S3UploadError:
			error_desc = "Unexpected error has occurred while uploading to S3.";
			break;
		default:
			error_desc = "An unknown error has occurred.";
			break;
	}
	if (error_code !== UploadErrorCode.OK)
		error_desc += " Please contact Clueso support.";
	return error_desc;
}
