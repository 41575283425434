const blockquote = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 9.375H4.31875C4.43491 8.60146 4.71112 7.86066 5.12974 7.1999C5.54837 6.53914 6.1002 5.97295 6.75 5.5375L7.86875 4.7875L7.18125 3.75L6.0625 4.5C5.1208 5.12755 4.34857 5.97785 3.81433 6.97545C3.2801 7.97305 3.00038 9.08711 3 10.2188V14.375C3 14.7065 3.1317 15.0245 3.36612 15.2589C3.60054 15.4933 3.91848 15.625 4.25 15.625H8C8.33152 15.625 8.64946 15.4933 8.88388 15.2589C9.1183 15.0245 9.25 14.7065 9.25 14.375V10.625C9.25 10.2935 9.1183 9.97554 8.88388 9.74112C8.64946 9.5067 8.33152 9.375 8 9.375ZM16.75 9.375H13.0688C13.1849 8.60146 13.4611 7.86066 13.8797 7.1999C14.2984 6.53914 14.8502 5.97295 15.5 5.5375L16.6188 4.7875L15.9375 3.75L14.8125 4.5C13.8708 5.12755 13.0986 5.97785 12.5643 6.97545C12.0301 7.97305 11.7504 9.08711 11.75 10.2188V14.375C11.75 14.7065 11.8817 15.0245 12.1161 15.2589C12.3505 15.4933 12.6685 15.625 13 15.625H16.75C17.0815 15.625 17.3995 15.4933 17.6339 15.2589C17.8683 15.0245 18 14.7065 18 14.375V10.625C18 10.2935 17.8683 9.97554 17.6339 9.74112C17.3995 9.5067 17.0815 9.375 16.75 9.375Z"
				fill={color}
			/>
		</svg>
	);
};
export default blockquote;
