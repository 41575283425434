import {
	useActive,
	useChainedCommands,
	useCommands,
	useRemirrorContext,
} from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import Icon from "../../../../../../ui/Icon/Icon";
// import { orderedList } from '../../../../../../assets/svgs/text-editor-toolbar/ol';
import blockquote from "../../../../../../assets/svgs/text-editor-toolbar/blockquote";
import ToolbarButton from "../ToolbarButton/ToolbarButton";
import "./BlockquoteButton.css";
import WhiteHoverTip from "../../../../../../ui/WhiteHoverTip/WhiteHoverTip";
type BlockquoteButtonProps = {
	disabled?: boolean;
};

const BlockquoteButton: React.FC<BlockquoteButtonProps> = (props) => {
	const commands = useChainedCommands() as any;
	const handler = (color?: string) => {
		commands.toggleBlockquote(color).focus().run();
	};

	const active = useActive();

	const srcImg = blockquote("white");
	const items = [
		{
			active: () => active.blockquote({ nodeBlockquoteColor: "gray" }),
			handler: () => handler("gray"),
			source: blockquote("#ffffff"),
		},
		{
			active: () => active.blockquote({ nodeBlockquoteColor: "blue" }),
			handler: () => handler("blue"),
			source: blockquote("#3BA6FF"),
		},
		{
			active: () => active.blockquote({ nodeBlockquoteColor: "green" }),
			handler: () => handler("green"),
			source: blockquote("#27BE69"),
		},
		{
			active: () => active.blockquote({ nodeBlockquoteColor: "yellow" }),
			handler: () => handler("yellow"),
			source: blockquote("#FFBF0F"),
		},
		{
			active: () => active.blockquote({ nodeBlockquoteColor: "red" }),
			handler: () => handler("red"),
			source: blockquote("#F2415A"),
		},
	];

	return (
		<div className="gigauser-texteditor-toolbar-blockquote-dropdown">
			<ToolbarButton
				disabled={props.disabled}
				active={() => active.blockquote()}
				handler={() => {}}
				source={srcImg}
				newHoverTip={<WhiteHoverTip text="Quote Block" />}
				dropdownMenu={items.map((x) => {
					return {
						active: x.active,
						handler: x.handler,
						source: x.source,
					};
				})}
			/>
		</div>
	);
};
export default BlockquoteButton;
