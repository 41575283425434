import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { helpSaver as saver } from "@gigauser/common/src/network/helpcenterSaver";
import HelpCenter, {
	HelpCenterProps,
} from "@gigauser/common/src/help-center/HelpCenter/HelpCenter";
import { GigaUserApi } from "@giga-user-fern/api";
import { GuideData } from "@giga-user-fern/api/types/api/resources/guides";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Collection } from "@giga-user-fern/api/types/api/resources/collections";
import logger from "@gigauser/common/src/utils/logger";
import { isProdDomain } from "@gigauser/common/src/help-center/utils/routeUtils";
import GridLoader from "@gigauser/common/src/ui/loaders/GridLoader/GridLoader";
import { getBrandingColor } from "@gigauser/common/src/core/utils/styleUtils";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import ExtHelpCenter from "./templates/v2/ExtHelpCenter";
import ExtHelpCenterContext from "./templates/v2/ExtHelpCenterContext";
import { Provider, useDispatch } from "react-redux";
import store from "./redux/store";
import { HelpCenterConfig } from "@giga-user-fern/api/types/api";
import posthog from "posthog-js";
import LogoLoader from "@gigauser/common/src/ui/loaders/LogoLoader/LogoLoader";
import orgSetupData, {
	getPageTitle,
	PosthogKeys,
} from "@gigauser/common/src/setup/orgSetupData";
import { loadPostHogDynamically } from "@gigauser/common/src/core/analytics/analytics";
import { ChakraProvider } from "@chakra-ui/react";
import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";
import PasswordPrompt from "./templates/v2/PasswordPrompt/PasswordPrompt";
import { useAuth0 } from "@auth0/auth0-react";
import SignIn from "@gigauser/common/src/auth/SignIn";
import EmbedViewer from "./templates/v2/EmbedViewer/EmbedViewer";
import { useAppDispatch } from "./redux";
// @ts-ignore
import { loadReoScript } from "reodotdev";

//9ca802c0-d09f-45ba-82fb-cd3095c9d754
export type HelpCenterPage = "Home" | "Browser" | "Unlisted" | "Embed";

type AppProps = {
	page?: HelpCenterPage;
};

const App: React.FC<AppProps> = (props) => {
	const [orgId, setOrgId] = useState<string | null>(null);
	const [helpCenterConfig, setHelpCenterConfig] = useState<HelpCenterConfig>({
		topbarTitle: "",
		creatorVisibilityFlag: true,
		themeToggleFlag: true,
		feedbackFlag: true,
		videoBrandingFlag: true,
		logoSize: 6.7,
		searchTitle: "Search for help...",
		openDocKeyword: "Explore",
		homePageName: "Help Center",
		subcollectionsKeyword: "sub-collections",
		guidesKeyword: "guides",
		createdByKeyword: "Created By",
		feedbackPrompt: "Did you find this article helpful?",
		feedbackQuestion: "Thank you! How can we help you better?",
		feedbackPlaceholder: "Enter your feedback here...",
		feedbackSubmitText: "Submit",
		feedbackRevertText: "",
		disableDownload: false,
		leftTabs: [],
		rightTabs: [],
		defaultTheme: "light",
		lightLogoUrl: "",
		darkLogoUrl: "",
		faviconUrl: "",
	});

	logger.debug("orgId: ", orgId);

	const { device } = useWindowSize();
	const { getAccessTokenSilently, logout, isAuthenticated } = useAuth0();
	const [loading, setLoading] = useState(true);
	const [socialSignIn, setSocialSignIn] = useState(false);
	const [passwordScreen, setPasswordScreen] = useState(false);
	const init = async (orgId: string) => {
		// const r = await saver.initialiseFromOrgId(orgId)
		logger.debug("saver is now: ", saver);
		setLoading(false);
	};

	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {}, []);

	useEffect(() => {
		if (saver.organization) {
			document.title = `${getPageTitle(saver.organization, helpCenterConfig)}`;

			const link =
				document.querySelector("link[rel*='icon']") ||
				(document.createElement("link") as any);
			link.type = "image/x-icon";
			link.rel = "shortcut icon";
			link.href =
				helpCenterConfig.faviconUrl ??
				`https://clueso-public-assets.s3.ap-south-1.amazonaws.com/${saver.organization.id}/logos/favicon`;
			document.head.appendChild(link);
		}
	}, [saver.organization]);

	const initOrganization = (orgId: GigaUserApi.Id) => {
		setOrgId(orgId);
		logger.debug("got org: ", orgId);

		const setup_data = orgSetupData[orgId];
		if (
			setup_data?.posthog &&
			orgId !== "c7c44004-d4fa-429c-80c2-c3092b5acd5a"
		) {
			const { api_host, posthog_api_key } = setup_data.posthog;

			loadPostHogDynamically(posthog_api_key, api_host, orgId);
		} else {
			const { api_host, posthog_api_key } = orgSetupData[
				"c7c44004-d4fa-429c-80c2-c3092b5acd5a"
			].posthog as PosthogKeys;

			loadPostHogDynamically(posthog_api_key, api_host, orgId);
			posthog.group("company", orgId);
		}
	};

	useEffect(() => {
		if (saver.organization) {
			return;
		}
		if (isProdDomain()) {
			// Check home parameter
			if (searchParams.get("home")) {
				localStorage.setItem(
					"clueso-helpcenter-home",
					decodeURIComponent(searchParams.get("home") as string),
				);
				searchParams.delete("home");
			}
			setSearchParams(searchParams);
			const staging_jwt = new URLSearchParams(window.location.search).get(
				"jwt",
			);
			saver.initialiseSaver(staging_jwt || "ignore");
			saver.fetchOrgAndConfig().then((orgAndConfig) => {
				if (orgAndConfig.ok) {
					setHelpCenterConfig(orgAndConfig.body.config);

					initOrganization(orgAndConfig.body.organization.id);
					setLoading(false);
				} else {
					// Check token
					if (
						orgAndConfig.error.error === "InvalidSessionError" &&
						orgAndConfig.error.content.redirectUrl
					) {
						const urlToken =
							searchParams.get("token") ||
							searchParams.get("jwt_token");
						if (!urlToken) {
							window.location.replace(
								orgAndConfig.error.content.redirectUrl +
									"/?redirect_uri=" +
									encodeURIComponent(window.location.href),
							);
						} else {
							saver
								.validateToken(urlToken)
								.then((resp) => {
									searchParams.delete("token");
									searchParams.delete("jwt_token");
									if (resp.ok && resp.body !== "ok") {
										localStorage.setItem(
											"clueso-helpcenter-user",
											resp.body,
										);
									}

									setSearchParams(searchParams);
									saver
										.fetchOrgAndConfig()
										.then((orgAndConfig) => {
											if (orgAndConfig.ok) {
												setHelpCenterConfig(
													orgAndConfig.body.config,
												);

												initOrganization(
													orgAndConfig.body
														.organization.id,
												);
												setLoading(false);
											}
										});

									return;
								})
								.catch((_) => {
									// TODO: Display some error and say try again
									// Perhaps set some state?
									urlParams.delete("token");
								});
						}

						return;
					}
					if (
						orgAndConfig.error.error === "InvalidSessionError" &&
						orgAndConfig.error.content.passwordRequired
					) {
						setPasswordScreen(true);
					} else if (
						orgAndConfig.error.error === "InvalidSessionError" &&
						orgAndConfig.error.content.socialSignIn
					) {
						setSocialSignIn(true);
					}
				}
			});

			return;
		}

		const urlParams = new URLSearchParams(window.location.search);
		let urlOrgId = urlParams.get("org");
		if (!urlOrgId && process.env.NODE_ENV === "development") {
			urlOrgId = "9ca802c0-d09f-45ba-82fb-cd3095c9d754";
		}

		setOrgId(urlOrgId);
		logger.debug("got org: ", urlOrgId);
		if (urlOrgId) {
			init(urlOrgId);
		}
	}, [searchParams, setSearchParams, isAuthenticated]);

	useEffect(() => {
		if (socialSignIn) {
			if (isAuthenticated) {
				console.log("Authenticated!");
				getAccessTokenSilently()
					.then((token) => {
						saver
							.validateToken(token)
							.then((resp) => {
								if (resp.ok && resp.body !== "ok") {
									localStorage.setItem(
										"clueso-helpcenter-user",
										resp.body,
									);
									saver
										.fetchOrgAndConfig()
										.then((orgAndConfig) => {
											if (orgAndConfig.ok) {
												setHelpCenterConfig(
													orgAndConfig.body.config,
												);
												initOrganization(
													orgAndConfig.body
														.organization.id,
												);
												setLoading(false);
											}
										});
								} else {
									console.log("logginh out");

									logout({
										logoutParams: {
											returnTo: window.location.origin,
										},
									});
								}
							})
							.catch((e) => {
								console.log("logginh out", e);
								logout({
									logoutParams: {
										returnTo: window.location.origin,
									},
								});
							});
					})
					.catch((e) => {});
			}
		}
	}, [socialSignIn, isAuthenticated]);

	useEffect(() => {
		if (orgId && orgSetupData[orgId]?.jsSnippet) {
			// Only reo
			const clientID = "c07f38f6d83bb7e";
			const scriptUrlPattern =
				"https://static.reo.dev/c07f38f6d83bb7e/reo_clueso.js";
			const reoPromise = loadReoScript({ scriptUrlPattern, clientID });
			reoPromise
				.then((Reo: any) => {
					Reo.init({ clientID });
				})
				.catch((error: any) => {
					console.error("Error loading Reo", error);
				});
		}
	}, [orgId]);

	if (socialSignIn) {
		if (isAuthenticated) {
			return <>Logging in...</>;
		}
		return (
			<div className="AppInitializer-auth">
				<div className="PlatformSignIn-container">
					<SignIn method="redirect" message="Knowledge base" />
				</div>
			</div>
		);
	}

	if (passwordScreen) {
		return (
			<PasswordPrompt
				submit={async (password: string) => {
					const resp = await saver.validateToken(password);
					if (resp.ok) {
						const orgAndConfig = await saver.fetchOrgAndConfig();

						if (orgAndConfig.ok) {
							setHelpCenterConfig(orgAndConfig.body.config);

							initOrganization(orgAndConfig.body.organization.id);
							setLoading(false);
							setPasswordScreen(false);
							return true;
						}
						return false;
					}
					return false;
				}}
			/>
		);
	}

	if (loading) {
		return <GridLoader center />;
	}

	if (!orgId) {
		logger.error("invalid URL!");
		return <GridLoader center />;
	}

	const helpCenterProps: HelpCenterProps = {
		navigator: useNavigate,
		environment: "ext-hc",

		search: saver.search,

		getOrganization: () => {
			return saver.organization;
		},

		fetchAllGuidePreviews: async () => {
			const guidePreviews = await saver.fetchAllGuidePreviews();
			return guidePreviews;
		},

		fetchAllChildren: async (collectionId?: string) => {
			const { guides, collections } = await saver.fetchAllChildren(
				collectionId,
				true,
			);
			return Promise.resolve({
				collections: collections,
				guidePreviews: guides,
			});
		},

		fetchGuidePreview: async (id: GigaUserApi.Id) => {
			const guidePreview = await saver.fetchGuidePreview(id);
			return guidePreview;
		},

		getPath: async (id: GigaUserApi.Id) => {
			const path = await saver.getPath(id);
			return path;
		},

		fetchGuideData: async (id: GigaUserApi.Id) => {
			const guideData = (await saver.fetchGuideData(id)) as GuideData;
			return guideData;
		},

		close: () => {},
	};

	if (props.page === "Embed") {
		return (
			<>
				<Provider store={store}>
					<EmbedViewer />
				</Provider>
			</>
		);
	}
	return (
		<ChakraProvider>
			<div className="App">
				{device === "laptop" ? (
					<Provider store={store}>
						<ExtHelpCenterContext.Provider value={helpCenterProps}>
							<ExtHelpCenter
								page={props.page || "Home"}
								helpCenterConfig={helpCenterConfig}
							/>
						</ExtHelpCenterContext.Provider>
					</Provider>
				) : (
					<Provider store={store}>
						<ExtHelpCenterContext.Provider value={helpCenterProps}>
							<HelpCenter {...helpCenterProps} />
						</ExtHelpCenterContext.Provider>
					</Provider>
				)}
			</div>
		</ChakraProvider>
	);
};

export default App;
