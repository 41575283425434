import {
	useActive,
	useChainedCommands,
	useCommands,
	useRemirrorContext,
} from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import Icon from "../../../../../../ui/Icon/Icon";
import editorCode from "../../../../../../assets/svgs/text-editor-toolbar/editor-code";
import ToolbarButton from "../ToolbarButton/ToolbarButton";
import WhiteHoverTip from "../../../../../../ui/WhiteHoverTip/WhiteHoverTip";
// import "..BlockTypeDropdown/BlockTypeDropdown.css";

type CodeTypeDropdownProps = {
	disabled?: boolean;
};

const CodeTypeDropdown: React.FC<CodeTypeDropdownProps> = (props) => {
	const active = useActive();
	const languages = [
		"Kotlin",
		"Dart",
		"Swift",
		"JS",
		"TS",
		"Python",
		"HTML",
		"XML",
		"CSS",
		"JSON",
	];

	const commands = useChainedCommands() as any;

	const items = languages.map((language) => {
		return {
			active: () => active.codeBlock({ language: language }),
			handler: () => {
				commands.toggleCodeBlock({ language: language }).focus().run();
			},
			source: (
				<span className="ToolbarButton-dropdown-text-item">
					{language}
				</span>
			),
		};
	});

	return (
		<div className="CodeTypeDropdown">
			<ToolbarButton
				active={active.codeBlock}
				disabled={props.disabled}
				handler={() => {}}
				source={editorCode("white")}
				newHoverTip={<WhiteHoverTip text="Code Block" />}
				dropdownType="verticle"
				dropdownMenu={items.map((x) => {
					return {
						active: x.active,
						handler: x.handler,
						source: x.source,
					};
				})}
			/>
		</div>
	);
};
export default CodeTypeDropdown;
