import { GigaUserApi, GigaUserApiClient } from "@giga-user-fern/api";
import { HelpCenterConfig } from "@giga-user-fern/api/types/api";
import { captureEvent } from "../core/analytics/analytics";
import { Collection } from "../core/types/collections";
import { Organization } from "../core/types/guide";
import { setBranding } from "../core/utils/styleUtils";
import { rootCollection } from "../types/files";
import logger from "../utils/logger";
import { fetcher } from "./Adapter";
import saverconfig from "./_saverconfig";
// import { Id } from '../core/types/baseTypes';
const Id = GigaUserApi.Id;

let main_url = saverconfig.main_url;
function convertSrtToWebVtt(srtData: string) {
	// Add the 'WEBVTT' header and a newline
	let webVttData = "WEBVTT\n\n";

	// Replace SRT timestamp format with WebVTT timestamp format
	webVttData += srtData.replace(
		/(\d{2}):(\d{2}):(\d{2}),(\d{3})/g,
		"$1:$2:$3.$4",
	);

	return webVttData;
}
if (window) {
	/*
	if (window.location.hostname === "help.desklamp.io") {
		main_url = "https://help.desklamp.io";
	}
	if (window.location.hostname === "docs.plotline.so") {
		main_url = "https://docs.plotline.so";
	}
	if (window.location.hostname === "help.cloudeagle.ai") {
		main_url = "https://help.cloudeagle.ai";
	}
	if (window.location.hostname === "support.flagright.com") {
		main_url = "https://support.flagright.com";
	}
	if (window.location.hostname === "sandbox.support.flagright.com") {
		main_url = "https://sandbox.support.flagright.com";
	}
	if (window.location.hostname === "help.maintners.app") {
		main_url = "https://help.maintners.app";
	}
	if (window.location.hostname === "help.lucioai.com") {
		main_url = "https://help.lucioai.com";
	}
	if (window.location.hostname === "help.triomics.com") {
		main_url = "https://help.triomics.com";
	}
	if (window.location.hostname === "help.sprypt.com") {
		main_url = "https://help.sprypt.com";
	}
	*/
	if (
		!(
			window.location.hostname.endsWith(".clueso.io") ||
			window.location.hostname === "localhost"
		)
	) {
		main_url = "https://" + window.location.hostname;
	}
}

// const main_url = "https://help.desklamp.io"
type PresignedUrl = any;

function dataURItoBlob(dataURI: string) {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this

	try {
		const byteString = atob(dataURI.split(",")[1]);
		// separate out the mime component
		const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

		// write the bytes of the string to an ArrayBuffer
		const ab = new ArrayBuffer(byteString.length);

		// create a view into the buffer
		const ia = new Uint8Array(ab);

		// set the bytes of the buffer to the correct values
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		// write the ArrayBuffer to a blob, and you're done
		const blob = new Blob([ab], { type: mimeString });
		return blob;
	} catch (e) {
		logger.error("Error", e, dataURI);
		return dataURI;
	}
}

export const uploadToPresigned = async (
	body: any,
	presignedURL: PresignedUrl,
) => {
	const formData = new FormData();
	const data = { ...presignedURL };
	// data.fields.key = presignedURL.fields.key + "hello.txt"
	for (const key in data.fields) {
		formData.append(key, data.fields[key]);
	}
	formData.append("file", body);
	try {
		const resp = await fetch(data.url, { method: "POST", body: formData });
		logger.debug("UPLOADED", resp);
		if (!resp.ok) {
			// No upload
			logger.debug("BODY OF FAILED UPLOAD", body);
		}
		return resp;
	} catch (e: any) {
		logger.debug(e, e.status);

		try {
			captureEvent({
				eventName: "UploadError",
				value: { error: JSON.stringify(e) },
			});
		} catch (e) {}
		return Promise.resolve(null);
	}
};

class HelpCenterSaver {
	queryAPI: GigaUserApiClient;
	organization: Organization | null;
	helpCenterConfig: HelpCenterConfig | null;
	constructor() {
		this.queryAPI = new GigaUserApiClient({
			environment: main_url,
			fetcher: fetcher,
		});
		this.organization = null;
		this.helpCenterConfig = null;
	}
	staging = false;

	getBrandingColor = () => {
		return this.organization?.brandColor;
	};

	initialiseSaver = (token: string) => {
		this.queryAPI = new GigaUserApiClient({
			token: token,
			environment: main_url,
			fetcher: fetcher,
		});
	};

	fetchOrgAndConfig = async () => {
		const orgAndConfig =
			await this.queryAPI.helpcenterQueries.getOrgAndConfig();

		if (orgAndConfig.ok) {
			this.organization = orgAndConfig.body.organization;
			this.helpCenterConfig = orgAndConfig.body.config;
			setBranding(
				this.helpCenterConfig.brandColor,
				this.helpCenterConfig.backgroundColor,
			);
		}
		return orgAndConfig;
	};
	//checked ✅
	fetchAllGuidePreviews = async (onlyPublished = true) => {
		const allGuides =
			await this.queryAPI.helpcenterQueries.getAllGuidePreviews();

		if (allGuides.ok) {
			const guidePreviews = allGuides.body;

			if (onlyPublished)
				return guidePreviews.filter((i) => i.header.published === true);
			else return guidePreviews;
		} else {
			return [];
		}
	};

	fetchAllCollections = async () => {
		const allCollections =
			await this.queryAPI.helpcenterQueries.getAllCollections();

		if (allCollections.ok) {
			const collections = allCollections.body;

			return collections;
		} else {
			return [];
		}
	};

	// checked ✅
	fetchAllChildren = async (_parentId?: string, onlyPublished = true) => {
		let parentId = undefined;

		if (_parentId === "Collection_root") {
			parentId = undefined;
		} else if (_parentId) {
			parentId = GigaUserApi.Id(_parentId);
		}

		const allChildren =
			await this.queryAPI.helpcenterQueries.getAllChildren({
				parentId: parentId,
			});

		logger.debug("allChildren: ", allChildren);

		if (allChildren.ok) {
			const { guides, collections } = allChildren.body;
			if (onlyPublished) {
				const _guides = guides.filter((g) => g.header.published);
				return {
					guides: _guides,
					collections: collections,
				};
			} else {
				return allChildren.body;
			}
		} else {
			return {
				guides: [],
				collections: [],
			};
		}
	};

	getPath = async (id: string) => {
		logger.debug("saver getPath: ", id);

		const res = await this.queryAPI.helpcenterQueries.getPath({
			id: GigaUserApi.Id(id),
		});

		if (res.ok) {
			const path = res.body.collections;
			const full_path = [rootCollection, ...path];

			logger.debug("got path: ", full_path);

			return full_path as [Collection, ...Collection[]];
		} else {
			return false;
		}
	};

	validateToken = async (token: string) => {
		const resp = await this.queryAPI.helpcenterQueries.validateToken({
			tokenId: token,
		});
		return resp;
	};

	search = async (text: string) => {
		const searchResults = await this.queryAPI.helpcenterQueries.search({
			text: text,
		});
		if (searchResults.ok) {
			return searchResults.body;
		} else {
			return [];
		}
	};

	// checked ✅
	fetchGuideData = async (id: GigaUserApi.Id) => {
		// logger.debug("CLIENT API", thi)

		const rootGuides = await this.queryAPI.helpcenterQueries.getGuideData({
			id: id,
		});
		if (rootGuides.ok) {
			const guideData = rootGuides.body;
			if (guideData.video.generated?.subtitles) {
				const blob = new Blob(
					[convertSrtToWebVtt(guideData.video.generated?.subtitles)],
					{
						type: "text/vtt",
					},
				);
				guideData.video.generated.subtitles = URL.createObjectURL(blob);
			}
			return guideData;
		} else {
			return null;
		}
	};

	// getVideoEdits = async(id: GigaUserApi.Id) => {

	//     const videoEdits = await this.queryAPI.helpcenterQueries.getVideoEdits({
	//         id: id
	//     })

	//     if(videoEdits.ok){
	//         return videoEdits.body
	//     }

	//     console.error("Couldn't get video Edits! ", videoEdits)
	//     return null

	// }

	//checked ✅
	fetchGuidePreview = async (id: GigaUserApi.Id) => {
		const res = await this.queryAPI.helpcenterQueries.getGuidePreview({
			id: id,
		});

		if (res.ok) {
			const guidePreview = res.body;
			return guidePreview;
		} else {
			return null;
		}
	};
}

export const helpSaver = new HelpCenterSaver();
