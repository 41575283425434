import { HelpCenterConfig } from "@giga-user-fern/api/types/api";
import { Organization } from "../core/types/guide";

interface TopbarTab {
	label: string;
	url: string;
	localStorageValue?: string;
}

export type PosthogKeys = {
	api_host: string;
	posthog_api_key: string;
};

export type TopbarData = {
	left: TopbarTab[];
	right: TopbarTab[];
	posthog?: PosthogKeys;
	pageTitle?: string;
	topbarTitle?: string;
	showCreator?: boolean;
	hideThemeToggle?: boolean;
	backgroundColor?: string;
	hideFeedback?: boolean;
	hideVideoBranding?: boolean;
	logoSize?: number;
	descriptionColorInvert?: boolean;
	descriptionSize?: "xsmall" | "small" | "normal" | "medium";

	//Maintners
	searchTitle?: string;
	explore?: string;
	home?: string;
	subcollections?: string;
	guides?: string;
	createdByText?: string;

	//feedback
	feedbackText?: string;
	feedbackEnterFeedbackTitle?: string;
	feedbackEnterFeedbackPlaceholder?: string;
	feedbackSubmitText?: string;
	feedbackResultText?: string;

	//video player
	disableDownload?: boolean;

	//js snippet
	jsSnippet?: string;
};

type TopbarDataDictionary = Record<string, TopbarData>;

const orgSetupData: TopbarDataDictionary = {
	// Plotline
	"83cfe0d8-731d-4461-a350-22dd02485bee": {
		left: [],
		right: [
			{
				label: "Login",
				url: "https://app.plotline.so/flow",
			},
		],
		posthog: {
			api_host: "https://app.posthog.com",
			posthog_api_key: "phc_WokBiEScMdrKh86hcxQgyZc5x97dDh50WD6gwYcqagZ",
		},
		pageTitle: "Plotline Documentation",
		topbarTitle: "Documentation",
		disableDownload: true,
	},
	// CoinDCX
	"c74897fb-9b39-4631-8ad9-aa2940eb23b8": {
		left: [],
		right: [
			{
				label: "Login",
				url: "https://coindcx.com/login",
			},
			{
				label: "Chat Now",
				url: "https://coindcx.com",
			},
		],
		pageTitle: "Coin DCX Help & Support",
		topbarTitle: "Help & Support",
	},
	// Glowing
	"9e9eeefd-9cb5-42b9-a55b-f42ed145f5d2": {
		left: [],
		right: [
			{
				label: "Login",
				url: "https://app.glowing.io/",
			},
		],
	},
	// Finnoto
	"f2d1b46f-dc95-4311-a357-422548e5b268": {
		left: [],
		right: [
			{
				label: "Home",
				url: "https://finnoto.com/",
			},
		],
	},

	//Clueso
	"c7c44004-d4fa-429c-80c2-c3092b5acd5a": {
		left: [],
		right: [
			{
				label: "Book demo",
				url: "https://calendly.com/clueso_intro/neel",
			},
			{
				label: "Home",
				url: "https://clueso.io",
			},
		],
		// backgroundColor: "#edecfd", Background Color is depcrated, its handled using the database now
		posthog: {
			api_host: "https://app.posthog.com",
			posthog_api_key: "phc_K9xtjpstJIE86BCqAq7SLsbsWK31H7YggUQZIf8xf9x",
		},
		disableDownload: true,
		// hideVideoBranding: true,
		// jsSnippet: `!function(){alert("Hi")}()`,
		// descriptionColorInvert: true,
		// descriptionSize: "normal",
	},

	//FirstHR
	"8e3417ad-705f-4348-8de5-3052adaf56a1": {
		left: [],
		right: [
			{
				label: "Contact Us",
				url: "https://firsthr.app/contacts",
			},
			{
				label: "Log In",
				url: "https://panel.firsthr.app/signin",
			},
		],
	},

	//Aspire
	"d1725ae4-b8ae-4bbe-b339-ca58dbe4eb83": {
		left: [],
		right: [],
		pageTitle: "Aspire",
		topbarTitle: " ",
	},

	//Copy
	"d6e6ecda-277f-4747-830c-4e17f783c40c": {
		left: [],
		right: [
			{
				label: "Log In",
				url: "https://app.copy.ai/login",
			},
		],
	},

	//Contracts
	"cc59483a-b102-4b0c-b2f8-5b61bb11a67b": {
		left: [],
		right: [
			{
				label: "Contact Us",
				url: "https://cal.com/alexboone",
			},
		],
	},

	//Cloudeagle
	"6467aaa9-e711-4452-97cb-eadb88db50a4": {
		left: [],
		right: [],
	},

	//Scytale
	"a6050e3b-a4d8-4237-a7dd-be990f5cde20": {
		left: [],
		right: [
			{
				label: "Log In",
				url: "https://app.scytale.ai/",
			},
		],
	},

	//Kapture
	"3c31fe1a-8bb8-4af2-9998-32d0aa659865": {
		left: [],
		right: [],
		pageTitle: "Kapture Knowledge Base",
		topbarTitle: "Knowledge Base",
		showCreator: true,
	},

	//Kapture Dev
	"b3ef123e-aff3-4e84-97f2-eaa39995c4c7": {
		left: [],
		right: [],
		pageTitle: "Kapture Developer Docs",
		topbarTitle: "Developer Docs",
		showCreator: true,
	},

	//Bounce
	"99a72785-07dc-4fdc-bbe3-c683269868a6": {
		left: [],
		right: [
			{
				label: "Contact Us",
				url: "https://www.bouncelife.com/contact",
			},
			{
				label: "Log In",
				url: "https://www.bouncelife.com/login",
			},
		],
	},

	//ImagineCreate
	"4536ec46-3b52-4fe8-8f28-87559dc8ec37": {
		left: [],
		right: [
			{
				label: "Contact Us",
				url: "mailto:support@imaginecreate.ai",
			},
			{
				label: "Log In",
				url: "http://imaginecreate.ai/",
			},
		],
		pageTitle: "ImagineCreate",
		topbarTitle: " ",
	},

	//Tennr
	"2a0d9fa6-23d5-4e0a-8177-50f584f2b6ca": {
		left: [],
		right: [
			{
				label: "Run your workflow",
				url: "https://app.tennr.com/run",
			},
		],
	},

	//Spry
	"b1b45523-ac73-47ca-8bc8-7f683e82a3e4": {
		left: [],
		right: [
			{
				label: "Home",
				url: "https://provider.sprypt.com/",
			},
		],
		hideThemeToggle: true,
		backgroundColor: "#edecfd",
		descriptionColorInvert: true,
	},

	//Flagright
	"bf61638b-9a21-4036-aca9-b2328dcdac84": {
		left: [],
		right: [
			{
				label: "Customer Portal",
				url: "http://tickets.flagright.com/",
			},
		],
	},

	//Invoca
	"2da08f92-aa89-4930-89df-86c5996dee57": {
		left: [],
		right: [],
		pageTitle: "Invoca Preview Center",
		topbarTitle: "Preview Center",
	},

	//Mazlo
	"e9ce79a0-6952-4c75-a34a-e252d78f8745": {
		left: [],
		right: [],
		hideFeedback: true,
		hideVideoBranding: true,
	},

	//Newmail
	"bd308808-17a8-402c-be1c-c7fcee3e66ad": {
		left: [],
		right: [
			{
				label: "Home",
				url: "http://app.newmail.ai/",
			},
			{
				label: "Support Ticket (EN)",
				url: "https://forms.gle/qpVjNwcD27oWhubc8",
			},
			{
				label: "Support Ticket (FR)",
				url: "https://forms.gle/cpvAKe6ogwjz3FMm8",
			},
		],
	},

	//Maintners
	"c8e7e6b2-3dbb-4de7-91c3-31a9b1de295b": {
		left: [],
		pageTitle: "Maintners Centre d'aide",
		topbarTitle: "Centre d'aide",
		right: [
			{
				label: "Accueil GMAO",
				url: "http://maintners.herokuapp.com/",
				localStorageValue: "clueso-helpcenter-home",
			},
		],
		searchTitle: "Recherche ...",
		explore: "Explorer",
		subcollections: "sous-collections",
		home: "Accueil",

		feedbackText: "Cet article vous a-t-il été utile ?",
		feedbackEnterFeedbackTitle:
			"Merci ! Comment pouvons-nous mieux vous aider ?",
		feedbackEnterFeedbackPlaceholder: "Entrez vos commentaires...",
		feedbackSubmitText: "Soumettre",
		feedbackResultText: "Merci d'avoir soumis vos commentaires !",
		createdByText: "Créé par",

		logoSize: 10.7,
	},
	//Pipedirecto/Ambit
	"fb60b411-0dd6-44b6-8f03-a3771b9ecff2": {
		left: [],
		right: [
			{
				label: "Home",
				url: "https://ambit.la/",
			},
		],
	},
	// Tortoise Organization Benefits
	"bff3879a-d383-4b32-96d0-703bee94d35b": {
		left: [],
		pageTitle: "Organization Benefits Help Center",
		topbarTitle: "Organization Benefits Help Center",
		right: [
			{
				label: "Home",
				url: "https://tortoise.pro",
			},
		],
	},
	// Tortoise Employee Benefits
	"50df7133-e506-4e21-8893-b9d31bdc4398": {
		left: [],
		pageTitle: "Employee Benefits Help Center",
		topbarTitle: "Employee Benefits Help Center",
		right: [
			{
				label: "Home",
				url: "https://tortoise.pro",
			},
		],
	},
	// Tortoise Lessor
	"3896ed7e-b558-4e98-bf21-c259587801e3": {
		left: [],
		pageTitle: "Lessor Help Center",
		topbarTitle: "Lessor Help Center",
		right: [
			{
				label: "Home",
				url: "https://tortoise.pro",
			},
		],
	},
	// Tortoise Vendor
	"07802def-ef13-4523-a3cc-c6c39dd1a291": {
		left: [],
		pageTitle: "Vendor Help Center",
		topbarTitle: "Vendor Help Center",
		right: [
			{
				label: "Home",
				url: "https://tortoise.pro",
			},
		],
	},

	//virti
	"8559cd0c-96c9-4dee-8fe4-9c66f1fb49f6": {
		left: [],
		right: [
			{
				label: "Home",
				url: "https://www.virti.com/",
			},
		],

		logoSize: 3.7,
	},

	//triomics
	"9337905a-ab51-46d2-a272-7b7cbaba08cd": {
		left: [],
		right: [
			{
				label: "Home",
				url: "https://www.triomics.com/",
			},
		],
		logoSize: 9,
	},

	//altana
	"187d5ccb-35d2-485a-ac43-6cca25ed1092": {
		left: [],
		right: [
			{
				label: "Home",
				url: "https://www.altana.ai/",
			},
		],
		hideVideoBranding: true,
	},

	//Salvy
	"8e96a703-9d89-418a-b52c-c3fe3d8fc21a": {
		left: [],
		right: [
			{
				label: "Início",
				url: "https://salvy.com.br/",
			},
		],
		searchTitle: "Pesquisar ...",
		explore: "Explorar",
		subcollections: "subcoleções",
		guides: "guias",
		home: "Início",

		pageTitle: "Central de Ajuda Salvy",
		topbarTitle: "Central de Ajuda",

		feedbackText: "Você achou este artigo útil?",
		feedbackEnterFeedbackTitle: "Obrigado! Como podemos ajudá-lo melhor?",
		feedbackEnterFeedbackPlaceholder: "Digite seus comentários...",
		feedbackSubmitText: "Enviar",
		feedbackResultText: "Obrigado por enviar seus comentários!",
		createdByText: "Criado por",
	},

	//Microhealth
	"5927bc5e-6330-4b9a-b454-e3fe21cc1199": {
		left: [],
		right: [
			{
				label: "Home",
				url: "https://microhealth.com/",
			},
		],
		pageTitle: "Microhealth University",
		topbarTitle: "University",
	},

	//Reo
	"d06f019c-da0b-4d5b-9fdd-9630e7fd5d0f": {
		left: [],
		right: [],
		jsSnippet: `!function(){var e,t,n;e="c07f38f6d83bb7e",t=function(){Reo.init({clientID:"c07f38f6d83bb7e"})},(n=document.createElement("script")).src="https://static.reo.dev/"+e+"/reo.js",n.async=!0,n.onload=t,document.head.appendChild(n)}();`,
		descriptionColorInvert: true,
		descriptionSize: "small",
	},
};

export const getPageTitle = (
	org: Organization | null,
	helpCenterConfig: HelpCenterConfig | null | undefined,
) => {
	return `${org?.name} ${helpCenterConfig?.pageTitle && helpCenterConfig.pageTitle !== "" ? helpCenterConfig.pageTitle : helpCenterConfig?.topbarTitle && helpCenterConfig?.topbarTitle !== "" ? helpCenterConfig?.topbarTitle : "Help Center"}`;
};

export const getTopbarTitle = (org: Organization | null) => {
	if (org && orgSetupData[org.id]) {
		return orgSetupData[org.id].topbarTitle || "Help Center";
	} else {
		return "Help Center";
	}
};

export default orgSetupData;
